import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();
export class DigitalPlanning {
    constructor() {}
    getSavedDigitalPlans(orgId) {
        const uri = `digitalplan/get_plans?orgId=${orgId}`
        return apiHelper.get(uri, orgId)
    }
    digitalPlanGeneration(data){
        const uri = `digitalplan/generate`
        return apiHelper.post(uri,data)
    }
    getDigitalPlanningData(id){
        const uri = `digitalplan?id=${id}`
        return apiHelper.get(uri);
    }
    getPlatformWiseBudget(type){
        const uri = `digitalplan/budget_by_platform?platform=${type}`
        return apiHelper.get(uri)
    }
    getAdtypeWiseBudget(type){
        const uri = `digitalplan/budget_by_adtype?adtype=${type}`
        return apiHelper.get(uri)
    }
    getChannelWiseBudget(type){
        const uri = `digitalplan/metrics_by_platform?platform=${type}`
        return apiHelper.get(uri)
    }
    getPerformancePlatform(type){
        const uri = `digitalplan/performance/metrics_by_platform?platform=${type}`
        return apiHelper.get(uri)
    }
    getBrandCate(user){
        const uri = `get_brand?userMail=${user}`
        return apiHelper.get(uri)
    }

}